export const prod_url = "https://realopinie.pl/"
const dev_url = process.env.DEV_URL + ":" + process.env.PORT + "/"

export const api_endpoint = process.env.NODE_ENV == "development" ? dev_url + "api" : prod_url + "api"  // Server side axioslarda gerekli.
export const site_img_path = "https://storage.googleapis.com/realopinie/site_img/"
export const user_review_url_text = "-opinie-"

export const lang = "pl"

// Google Analytcs
export const analytics_enabled = process.env.NODE_ENV == "development" ? false : true
export const gtag_id = "G-TN9YX94MM9"

// Adsense
export const adsense_code = "ca-pub-1080205330301131"
export const adsense_enabled = process.env.NODE_ENV == "development" ? false : true

// Hotjar
export const hotjar_enabled = false

// Sentry
export const sentry_dsn = "https://482654ceea054dcea8a6968e4cfc0c35@o723475.ingest.sentry.io/5793219"